import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./JS/ProtectedRouteComponent";
import styles from "./CSS/Main.module.css";
// Lazy loading components
const Login = lazy(() => import("./JS/Login/Login"));
const EmailVerified = lazy(() => import("./JS/Login/EmailVerified"));
const LinkExpired = lazy(() => import("./JS/Login/LinkExpired"));
const VerifyEmail = lazy(() => import("./JS/Login/VerifyEmail"));
const ResetPasswordSent = lazy(() => import("./JS/Login/ResetPasswordSent"));
const NotLoggedIn = lazy(() => import("./JS/Login/NotLoggedIn"));
const Main = lazy(() => import("./JS/RoadDisplay/Main"));
const RoadDisplay = lazy(() => import("./JS/RoadDisplay/RoadDisplay"));
const ProcessSeasonal = lazy(() =>
  import("./JS/Seasonal/ProcessSeasonalChange")
);
const ProcessSingle = lazy(() => import("./JS/SingleDay/ProcessSingleDay"));
const SinglePCI = lazy(() => import("./JS/SingleDay/SingleDayPCI"));
const SeasonalPCI = lazy(() => import("./JS/Seasonal/SeasonalChangePCI"));
const SeasonalSurface = lazy(() =>
  import("./JS/Seasonal/SeasonalChangeSurface")
);
const ForgotPass = lazy(() => import("./JS/Login/ForgotPass"));
const CreateAccount = lazy(() => import("./JS/Login/CreateAccount"));
const ResetPassword = lazy(() => import("./JS/Login/ResetPassword"));
const PasswordResetSuccess = lazy(() =>
  import("./JS/Login/PasswordResetSuccess")
);
const Traffic = lazy(() => import("./JS/Traffic/Traffic"));
const SingleSurface = lazy(() => import("./JS/SingleDay/SingleDaySurface"));

const App = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div className={styles.loader_container}>
            <div className={styles.loader}></div>
            <div className={styles.loader_text}>Loading...</div>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/Main"
            element={
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>
            }
          />
          <Route
            path="/RoadDisplay"
            element={
              <ProtectedRoute>
                <RoadDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ProcessSingle"
            element={
              <ProtectedRoute>
                <ProcessSingle />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ProcessSeasonal"
            element={
              <ProtectedRoute>
                <ProcessSeasonal />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SinglePCI"
            element={
              <ProtectedRoute>
                <SinglePCI />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SingleSurface"
            element={
              <ProtectedRoute>
                <SingleSurface />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SeasonalPCI"
            element={
              <ProtectedRoute>
                <SeasonalPCI />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SeasonalSurface"
            element={
              <ProtectedRoute>
                <SeasonalSurface />
              </ProtectedRoute>
            }
          />
          <Route path="/ForgotPass" element={<ForgotPass />} />
          <Route path="/EmailVerified" element={<EmailVerified />} />
          <Route path="/ResetPasswordSent" element={<ResetPasswordSent />} />
          <Route path="/NotLoggedIn" element={<NotLoggedIn />} />
          <Route path="/LinkExpired" element={<LinkExpired />} />
          <Route path="/VerifyEmail" element={<VerifyEmail />} />
          <Route path="/CreateAccount" element={<CreateAccount />} />
          <Route
            path="/PasswordResetSuccess"
            element={<PasswordResetSuccess />}
          />
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route
            path="/Traffic"
            element={
              <ProtectedRoute>
                <Traffic />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
