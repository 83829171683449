import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "./UserContext";
import styles from "../CSS/Main.module.css";

const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  console.log("Protected route check, isLoggedIn:", user.isLoggedIn);
  const userId = localStorage.getItem("user_id");
  if (user.isChecking) {
    return (
      <div className={styles.loader_container}>
        <div className={styles.loader}></div>
        <div className={styles.loader_text}>Establishing Session...</div>
      </div>
    );
    // Or some loading component
  }

  if (!user.isLoggedIn) {
    // Redirect to the login page if not logged in
    return <Navigate to="/NotLoggedIn" />;
  }
  if (!userId) {
    // Redirect them to the login page, but save the current location they were trying to go to
    return <Navigate to="/" />;
  }
  return children; // Render children if all checks pass
};

export default ProtectedRoute;
