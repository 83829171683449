import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UserProvider } from "./JS/UserContext"; // make sure this import is correct based on your file structure

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  // <React.StrictMode>
  <UserProvider>
    <App />
  </UserProvider>
  // </React.StrictMode>
);
