import React, { createContext, useContext, useState, useEffect } from "react";

export const UserContext = createContext();

console.log("Outside userProvider");

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ isLoggedIn: false, isChecking: true });
  console.log(" in user context");
  useEffect(() => {
    const checkSession = async () => {
      try {
        console.log(" in user context, in try part");
        const response = await fetch(
          "https://e2e-mh-road.duckdns.org/check_session",
          {
            credentials: "include",
          }
        );
        const data = await response.json();
        console.log(data);
        setUser({ isLoggedIn: data.logged_in, isChecking: false });
      } catch (error) {
        console.error("Error checking session:", error);
        console.log(" in user context, in catch part");
        setUser({ isLoggedIn: false, isChecking: false });
      }
    };
    console.log(" in user context, before checksessionfunction( line 24");
    checkSession();

    // Cleanup function
    return () => {
      setUser({ isLoggedIn: false, isChecking: true });
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
